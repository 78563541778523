import useStoryblok from '../layouts/useStoryblok'
import Template from '../templates'

const NotFoundPage = ({ location }) => {
  let { story, ...props } = useStoryblok(null, location)

  let content = <h1>Not found</h1>
  if (story && 'content' in story)
    content = <Template component={story?.content?.component} blok={story.content} {...props} />
  return content
}

export default NotFoundPage
